<template>
    <v-parallax
    dark
    :src="require('../assets/hawaii2016.jpg')"
    height="1400"
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        class="text-center"
         md="6"
      >
        <h1 class="display-4 font-weight-thick mb-4">
          AKIVA
        </h1>
        
        <h4 class="subheading">
          Akiva LLC is a consulting company with a passion and dedication to helping clients succeed. Specializing in blending the worlds of business and technology strategy, Akiva works with larger organizations to maximize productivity and ensure positive business outcomes.
        </h4>

      </v-col>
    </v-row>
    <v-row
      align="top"
      justify="center"
      class="mb-4"
    >
    <v-col
        class="text-left"
        cols="11"
        >
      <v-card
          color="rgb(0, 0, 0, 0.0)"
          elevation="0"
          >  
            <v-row
      
      justify="center"
    >
          <v-col
            class="text-left"
            cols="12"
            md="6"
          >
            <v-card
              elevation="0"
              color="rgb(0, 0,204, 0.5)"
              height="100%"
              class="white--text"
              >  
              <v-card-text class="white--text">
                <h1 class="display-2 white--text">Core Beliefs</h1><br>  
                <v-divider class="ma-2" color="white"></v-divider>
                <v-spacer></v-spacer>
                <div class="text-h6">
                Long term successful relationships come from investing in clients, employees and partners. This can be broken down into three core beliefs.<br><h1> </h1>
              
                  <v-icon
                  color="white"
                   class='ma-2'
                   large>
                      mdi-truck-fast
                  </v-icon> 
                  Put client success above your own
                  <h1></h1>
                  <v-icon
                   class='ma-2'
                    color="white"
                    large>
                      mdi-account-check
                  </v-icon> 
                    Treat people like human beings
                   <h1></h1>
                  <v-icon
                   class='ma-2'
                    color="white"
                    large>
                      mdi-trophy-outline
                  </v-icon> 
                    Compensate people fairly
                   <h1></h1>
                </div>
              </v-card-text>
            </v-card>
        </v-col>
        <v-col
          class="text-left"
          cols="12"
          md="6"
        >
 <v-card
              elevation="0"
              color="rgb(0, 104,204, 0.5)"
              height="100%"
              class="white--text"
              >  
              <v-card-text class="white--text">
                <h1 class="display-2 white--text">Our Objective</h1><br>
                <v-divider class="ma-2" color="white"></v-divider>
                <v-spacer></v-spacer>
                <div class="text-h6">
                To improve the ability for medium to large sized organizations to execute technology solutions at the cadence of business needs.  At this point, almost every company is a ‘technology company’, or is becoming one in today’s evolving business climate.  Building in speed is a given, but we also believe building in flexibility and the agility to pivot will be critical to the success of any organization. <br><h1> </h1>
                
                 
                </div>
              </v-card-text>
            </v-card>
        </v-col>
         </v-row>  

      </v-card>
      </v-col>
    </v-row>
    </v-parallax>
</template>
<script>

export default {
  name: 'About',
  components: {

  }
}
</script>