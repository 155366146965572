import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VueMeta  from 'vue-meta';
import VueGtag from "vue-gtag";
import Vuetify from 'vuetify';


Vue.config.productionTip = false
Vue.use(VueMeta)
Vue.use(VueGtag, {
 
  config: { id: "G-B0MQJH306Y"},
  pageTrackerScreenviewEnabled: true, 
  appName: 'AkivaWeb'
}, router);

new Vue({
  router,
  store,
  vuetify,  
  VueMeta,
  render: function (h) { return h(App) }
}).$mount('#app')

Vue.use(vuetify)

