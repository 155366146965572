<template>
<div>
  <HomePage/>
  <v-spacer></v-spacer>
  <AkivaServices/>
  <Markets/>
  <ContactSimple/>
</div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '../components/HelloWorld.vue'
import HomePage from '../components/HomePage.vue';
import AkivaServices from '../components/AkivaServices.vue';
import Markets from '../components/Markets.vue';
import ContactSimple from '../components/ContactSimple.vue';
export default {
  name: 'Home',
  components: {
    HelloWorld,
    HomePage,
    AkivaServices,
    Markets,
    ContactSimple,
  },
  metaInfo: {
    title: 'Enterprise Architecture, Data Management, and Consulting Services | Akiva',
    meta: [
      { vmid: 'description', name: 'description', content: 'Akiva offers a range of enterprise architecture, data management, and consulting services to help organizations improve their IT infrastructure and operations.' },
      { vmid: 'keywords', name: 'keywords', content: 'enterprise architecture, data management, consulting, services, cloud, data, Akiva, Ben Sufit, Denver' },
      { vmid: 'og:image', property: 'og:image', content: 'https://akiva-web.s3.us-east-2.amazonaws.com/img/Akiva2021grey.bbd659c4.png' },
      { vmid: 'og:title', property: 'og:title', content: 'Enterprise Architecture, Data Management, and Consulting Services | Akiva' },
      { vmid: 'og:description', property: 'og:description', content: 'Akiva offers a range of enterprise architecture, data management, and consulting services to help organizations improve their IT infrastructure and operations.' }
    ]
  },
  methods: {
    track() {
      this.$gtag.pageview("/home");
    },
  }
}
</script>

