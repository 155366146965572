<template>
 
  <v-app>  <metainfo><template v-slot:title="{ content }">{{ content ? `${content} | SITE_NAME` : `SITE_NAME` }}</template></metainfo>
    <NavBar/>
    <v-main>
      <router-view/> 
      <Footer/>
    </v-main>
   
  </v-app>
</template>

<script>
import Home from './views/Home';
import HelloWorld from './components/HelloWorld';
import HomePage from './components/HomePage.vue';
import AkivaServices from './components/AkivaServices.vue';
import NavBar from './components/NavBar.vue';
import Footer from './components/Footer.vue';
export default {
  name: 'App',

  components: {
    NavBar,
    Home,
    HomePage,
    AkivaServices,
    Footer,
   // HelloWorld,
  },
  metaInfo() {
    return {
    title: 'Enterprise Architecture, Data Management, and Consulting Services | Akiva',
    meta: [
      { vmid: 'description', name: 'description', content: 'Akiva offers a range of enterprise architecture, data management, and consulting services to help organizations improve their IT infrastructure and operations.' },
      { vmid: 'keywords', name: 'keywords', content: 'enterprise architecture, data management, consulting, services, cloud, data, Akiva, Ben Sufit, Denver' },
      { vmid: 'og:image', property: 'og:image', content: 'https://akiva-web.s3.us-east-2.amazonaws.com/img/Akiva2021grey.bbd659c4.png' },
      { vmid: 'og:title', property: 'og:title', content: 'Enterprise Architecture, Data Management, and Consulting Services | Akiva' },
      { vmid: 'og:description', property: 'og:description', content: 'Akiva offers a range of enterprise architecture, data management, and consulting services to help organizations improve their IT infrastructure and operations.' }
    ]
    }
  },

  data: () => ({
    //
  }),
};
</script>
