<template>
<v-container>
<v-row
      class="mt-3"
      align="center"
      justify="center"
      >
      <v-col         
        class="text-center"
        cols="12"
  
        <h1 class="display-1 font-weight-bold mb-3">
          AKIVA SERVICES
        </h1>
      </v-col>        
</v-row>
<v-col         
        class="text-center"
        cols="12"
        ><v-icon
         x-large
         color="blue darken-2"
        >mdi-minus</v-icon>
        <p class="subheading font-weight-regular">
          Specializing in blending the worlds of business and technology strategy, Akiva works with larger organizations to maximize productivity and ensure positive business outcomes.
         
        
        </p>
        </v-col>
<v-row>
    <v-container>
      <v-row 
      class="mx-3">
        <v-col
          v-for="(feature, i) in features"
          :key="i"
          cols="12"
          md="6"
        >
        <v-avatar 
         class="ma-1"
        size=75 
        color="grey lighten-3">
         <v-icon
         x-large>
          {{feature.icon}}
         </v-icon>      
        </v-avatar>
        
          <v-avatar-card
            name="feature.title"
            v-bind="features"
            align="left"
            horizontal
            class="font-weight-bold"
          >
          {{feature.title}} <br>
           <p class="font-weight-regular">{{feature.desc}} 
            </p >
          </v-avatar-card>
        </v-col>
      </v-row>
    </v-container>
</v-row>
</v-container>
</template>
<script>
  export default {
    name: 'AkivaServices',
   data: () => ({
      features: [
        {
          title: 'Enterprise Architecture',
          icon: 'mdi-math-compass',
          desc: 'Enterprise Architecture is the strategic design and management of an organization\'s IT assets, with a focus on building in resilience, flexibility, and speed of delivery. The ultimate goal is to align technology with the evolving needs of the business, enabling the organization to adapt and thrive in a constantly changing industry.',
        },
        {
          title: 'Data Strategy',
          icon: 'mdi-database',
          desc: 'Data has become a critical asset for organizations in today\'s digital landscape. Having a comprehensive data strategy is essential for leveraging this asset effectively. This strategy starts by gaining a deep understanding of an organization\'s data entities, systems of record, and how they impact the overall business. By identifying key data points and understanding their relationships, organizations can make informed decisions that drive business growth and innovation.',
        },
        {
          color: 'primary',
          dark: true,
          title: 'CI/CD',
          icon: 'mdi-play-box-multiple',
          desc: 'Continuous Integration and Continuous Deployment plays a foundational role in a modern IT organizations ability to deliver solutions quickly and with quality.  When achieved, the ‘bottle neck’ of business solutions shifts from IT delivery timeframes to becomes the ability of an organizations to think of creative solutions. ',
        },
        {
          title: 'Cloud Native',
          icon: 'mdi-cloud',
          desc: 'Beyond the buzz word nature of ‘cloud native’ solutions is the understanding of the value, flexibility and cost advantage of embracing a modern IT infrastructure.  The ‘pay-as-you-go’ and ‘only-for-what-you-use’ models allows all organizations to harness technology capabilities previously left to a small handful of the mega tech companies.',
        },
        {
          title: 'M&A due diligence',
          icon: 'mdi-chess-knight',
          desc: 'Mergers and Acquisitions are part of a healthy business’s growth strategy.  But organizations can be complicated, and it can be hard to understand what you are buying.  A comprehensive due diligence approach will aluminate key aspects of where an organization is on a maturity curve.  This empowers the acquiring company to understand the investment, pay a fair price, and what additional funds could be required to bring the target company up to enterprise standards.',
        },
        {
          title: 'Team Building',
          icon: 'mdi-account-group',
          desc: 'Leveraging consulting services is great for point in time projects.  However, depending on several factors, the ongoing maintenance and day to day operations of a solution is often better served by inhouse resources.  Although this goes against the ‘consultants come to study a problem, then stay to become part of it’ approach, we believe that we do better when our clients do better.  This entails ensuring that a client has the appropriate in-house talent for a solution to continue to deliver value long past the completion of a project.',
        },
      ],
    }),
  }
</script>