import axios from 'axios'
import qs from 'qs'
export default {
  sendsms(name, phone, email, message){
    let body = `Name: ${name} -- phone: ${phone} -- email: ${email} -- message: ${message}`
    console.log(body)
    var data = qs.stringify({
    'To': '+13232176232',
    'From': '+18707297754',
    'Body': body 
    });
    var config = {
      method: 'post',
      url: 'https://api.twilio.com/2010-04-01/Accounts/ACd2abb472556f27141c7e4b097734bc20/Messages.json',
      headers: { 
        'Authorization': 'Basic QUNkMmFiYjQ3MjU1NmYyNzE0MWM3ZTRiMDk3NzM0YmMyMDpjN2UwYjYwOTRlN2I3YzAyODM1OWQ5ZjYzNGRlNGM3MA==', 
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data : data
    };
    ///They at least need to provde an email or a phone number as well as a name and message

    if(name && (email || phone) && message){
      return axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
    } else {
      const invlaidPromise = new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve('foo');
        }, 300);
      });
      return invlaidPromise
    }
  }
}