<template>
    <v-parallax
    dark
    :src="require('../assets/darkbg.png')"
  >
  
  

   <v-container> 
   <h1 class="text-center">Industry Experience</h1>
   <v-col         
        class="text-center"
        cols="12"
        ><v-icon
         x-large
         color="white"
        >mdi-minus</v-icon>
    </v-col>
        <v-card
        class="d-flex justify-space-between mb-6"     
        color="rgb(255, 0, 0, 0.0)"
        flat
        tile
        >
            <v-card
                v-for="(feature, i) in features"
                :key="i"
                class="pa-2 white--text text-center"
                outlined
                tile
                color="rgb(255, 0, 0, 0.0)"           
            >
        <v-avatar 
            class="ma-1"
            size=75 
            color="grey lighten-3">
         <v-icon
            x-large
            >
          {{feature.icon}}
         </v-icon>      
            </v-avatar>
               <h2> {{feature.title}} </h2>
            </v-card>
        </v-card>
    </v-container>
</v-parallax>
</template>
<script>
  export default {
    name: 'Markets',
       data: () => ({
      features: [
        {
          title: 'Health Care',
          icon: 'mdi-medical-bag',
          desc: 'mdi-math-compass',
        },
        {
          title: 'Real Estate',
          icon: 'mdi-sign-real-estate',
        },
        {
          color: 'primary',
          dark: true,
          title: 'Energy',
          icon: 'mdi-power',
        },
      ],
    }),
  
  }
</script>
<style>
#markets {
  background-color: grey;
}
</style>