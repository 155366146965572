<template>
<div>
    <v-app-bar short class="hidden-sm-and-down"
      app  
      lite
    >
      <div class="d-flex align-center">
        <v-img
          alt="Akiva Logo"
          class="shrink mr-2"
          contain
          src="../assets/Akiva2021grey.png"
          transition="scale-transition"
          width="70"
        />
        <v-toolbar-title>Akiva</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn v-for="item in menu" :key="item.title" :to="item.link" text>
          {{ item.title }}
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>

    <v-app-bar  short class="hidden-md-and-up"
    app>
        <div class="d-flex align-center">
        <v-img
          alt="Akiva Logo"
          class="shrink mr-2"
          contain
          src="../assets/Akiva2021grey.png"
          transition="scale-transition"
          width="70"
        />
      </div>
    
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-app-bar-nav-icon color="black" v-bind="attrs" v-on="on"> </v-app-bar-nav-icon>
        </template>
        <v-list>
          <v-list-item v-for="(item, index) in menu" :key="index" :to="item.link">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
</div>
</template>
<script>
export default {
    name: 'NavBar',
  data: () => {
    return {
      menu: [
        { icon: 'home', title: 'Home', link: '/' ,  text: 'Home', loginshow: true },
        { icon: 'info', title: 'About', link: '/about',  text: 'About' ,loginshow: true},
        { icon: 'info', title: 'Contact Us', link: '/contact',  text: 'Contact Us' ,loginshow: true},
       ],

    }//color="blue darken-3"
  },
}
</script>
